/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Fragment, useState, useEffect } from 'react';
import './App.css';
import { ajax } from './util/ajax'

const style = {
  body: css`
    text-align: center;
    a {
      color: #aa0000;
    }
    a:hover {
      color: red;
    }
  `,
  page: css`
    background-color: #252535;
    color: #eee;
    height: 100vh;
    min-height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(21px + 2vmin);
  `,
  mainText: css`
    font-family: 'Oswald', Arial, sans-serif;
  `,
  count: css`
    color: #fff;
    font-size: 5rem;
  `,
  secondaryText: css`
    font-size: 0.75rem;
  `,
  progressBoxBox: css`
    width: 50%;
    display: block;
    height: 2vh;
    margin-bottom: 3vh;
  `,
  progressBox: css`
    width: 50%;
    height: 100%;
    background: transparent;
    border: 2px solid #eee;
    display: inline-block;
    vertical-align: top;
    margin: 0 1vw;
  `,
  progressBoxNumber: css`
    display: inline-block;
    font-size: calc(8px + 2vmin);
    font-family: 'Oswald', Arial, sans-serif;
    font-weight: 500;
    vertical-align: top;
    height: calc(2vh + 4px);
    line-height: calc(2vh + 4px);
  `,
  progressBar: css`
    background: #aa0000;
    height: 100%;
  `
}

function App() {
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [totalDeaths, setTotalDeaths] = useState('0')
  const [progressBarPercentage, setProgressBarPercentage] = useState(0)

  const getCount = async () => {
    const result = await ajax.get('/deaths_coronavirus_us')

    if (result.status === 'success') {
      const totalDeaths = result.data.data
      const totalDeathsText = parseFloat(totalDeaths).toLocaleString('en')
      const equivalent911s = (totalDeaths / 2977)
      const equivalent911sWithoutDecimals = Math.floor(equivalent911s)
      const percentageToNextWholeNumber = ((equivalent911s % 1) * 100)

      setCount(equivalent911sWithoutDecimals)
      setTotalDeaths(totalDeathsText)
      setProgressBarPercentage(percentageToNextWholeNumber)
      setIsLoading(false)
    } else {
      setIsError(true)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getCount()

    const intervalMinutes = 5
    const interval = setInterval(() => {
      getCount()
    }, intervalMinutes * 60 * 1000)
    return () => clearInterval(interval)
  }, [])

  const mainText = isLoading ? 'Loading...' :
    isError ? 'Error! Reload the page to try again.' :
      <Fragment>
        Coronavirus has killed<br/>as many Americans as<br /><strong css={style.count}>{count}</strong><br />consecutive 9/11s.
      </Fragment>

  const progressBar = isLoading ? null :
    isError ? null :
      <div css={style.progressBoxBox}>
        <div css={style.progressBoxNumber}>{count}</div>
        <div css={style.progressBox}>
          <div css={[style.progressBar, css`width:${progressBarPercentage}%`]} />
        </div>
        <div css={style.progressBoxNumber}>{count + 1}</div>
      </div>

  const secondaryText = isLoading ? null :
    isError ? null :
      <p css={style.secondaryText}>
        Total US coronavirus deaths: {totalDeaths}
        <br />
        <a href='https://www.worldometers.info/coronavirus/country/us/' target='_blank' rel='noopener noreferrer'>Source</a>
      </p>

  return (
    <div css={style.body}>
      <div css={style.page}>
        <p css={style.mainText}>
          {mainText}
        </p>
        {progressBar}
        {secondaryText}
      </div>
    </div>
  )
}

export default App
