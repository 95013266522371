const fetch = require('node-fetch')

export const ajax = {
  get: async (url) => {
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const json = await response.json()
      return {
        status: 'success',
        data: json
      }
    } catch (error) {
      return {
        status: 'error',
        data: error
      }
    }
  },
  post: async (url, opts) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(opts)
      })
      const json = await response.json()
      return {
        status: 'success',
        data: json
      }
    } catch (error) {
      return {
        status: 'error',
        data: error
      }
    }
  }
}
